<template>
	<errorContainer :error="erreur" :warning="warning">
		<transition name="slide">
			<div :class="$screen.width > 576 ? 'container-layout' : 'container-mobile'">
				<div class="form">
					<b-row>
						<b-col md="12">
							<h1 :class="[`${!$screen.sm ? 'main-page-title' : ''}`, { 'is-pwa': $isPwa() }]">{{ FormMSG(130, 'Documents') }}</h1>
						</b-col>
					</b-row>
					<b-row class="mt-3">
						<b-col class="p-0">
							<b-card class="pt-0 pr-0 pl-0" no-body>
								<b-card-text>
									<div>
										<b-tabs
											v-model="tabsValue"
											content-class="custom-content-tabs"
											nav-class="custom-nav-tabs"
											nav-wrapper-class="custom-nav-wrapper"
										>
											<b-tab v-if="canSeeDocument && useDoc" :title="FormMSG(189, 'Documents')">
												<div>
													<b-row>
														<b-col sm="12" md="6" lg="6" xl="6">
															<b-input-group v-if="$screen.width >= 992">
																<b-form-input type="text" v-model="filter" :placeholder="FormMSG(31, 'Type to Search')" />
																<b-input-group-append class="cursor-pointer">
																	<b-input-group-text class="btn-search">
																		<Search
																			color="#FFFFFF"
																			:size="16"
																			class="icon"
																			:stroke-width="2.5"
																			v-if="filter.length === 0"
																		/>
																		<X
																			color="#FFFFFF"
																			:size="16"
																			class="icon"
																			:stroke-width="2.5"
																			@click="filter = ''"
																			v-else
																		/>
																	</b-input-group-text>
																</b-input-group-append>
															</b-input-group>
														</b-col>
														<b-col sm="12" md="3" lg="3" xl="3" class="pt-1">
															<b-form-checkbox
																size="lg"
																:value="true"
																@change="handleChangeShowArchivedDocument"
																:unchecked-value="false"
																class="pj-cb pb-1"
															>
																{{ FormMSG(122, 'Show archived package') }}
															</b-form-checkbox>
														</b-col>
														<b-col sm="12" md="3" lg="3" xl="3">
															<b-button variant="outline-primary" block @click="NewDocument()">
																{{ FormMSG(17, 'New document') }}
															</b-button>
														</b-col>
													</b-row>
													<b-row class="mt-3">
														<b-col cols="12" xl="12">
															<b-overlay :show.sync="isBusyDataTable" rounded="lg" class="w-100">
																<CardListBuilder v-if="$screen.width < 992" :items="Docs" :fields="docfields">
																	<!-- @row-clicked="rowClicked" -->
																	<template slot="actions" slot-scope="data">
																		<b-button
																			variant="primary"
																			size="sm"
																			@click="rowClicked(data.item)"
																			title="Update document"
																			class="btn bg-transparent border-0"
																		>
																			<component
																				:is="getLucideIcon(ICONS.EDIT.name)"
																				:color="ICONS.EDIT.color"
																				:size="20"
																			/>
																		</b-button>
																		<b-button
																			variant="primary"
																			size="sm"
																			v-if="data.item.fileName.length > 0"
																			@click="viewDocument(data.item)"
																			title="View document"
																			class="btn bg-transparent border-0"
																		>
																			<component :is="getLucideIcon(ICONS.EYE.name)" color="#225CBD" :size="20" />
																		</b-button>
																		<b-button
																			class="btn bg-transparent border-0"
																			variant="danger"
																			size="sm"
																			v-if="showBtnTrashByStatus(data)"
																			@click="delItem(data.item.id)"
																			title="Delete document"
																		>
																			<component
																				:is="getLucideIcon(ICONS.TRASH.name)"
																				:color="ICONS.TRASH.color"
																				:size="20"
																			/>
																		</b-button>
																	</template>
																</CardListBuilder>
																<b-table
																	v-if="$screen.width >= 992"
																	:hover="hover"
																	responsive="sm"
																	sticky-header="900px"
																	ref="myTable"
																	selectable
																	:selectedVariant="selectedColor"
																	:select-mode="selectMode"
																	:items="Docs"
																	style="text-align: left"
																	:fields="docfields"
																	:current-page="currentPage"
																	:filter="filter"
																	:per-page="perPage"
																	@row-clicked="rowClicked"
																	bordered
																	small
																	show-empty
																	:empty-text="FormMSG(264, 'No document found')"
																	:head-variant="hv"
																>
																	<template v-slot:cell(view)="data">
																		<b-button
																			variant="primary"
																			size="sm"
																			v-if="data.item.fileName.length > 0 || data.item.childDocList.length > 0"
																			@click="viewDocument(data.item)"
																			class="btn bg-transparent border-0"
																		>
																			<component
																				:is="getLucideIcon(ICONS.EYE.name)"
																				:color="ICONS.EYE.color"
																				:size="20"
																			/>
																		</b-button>
																	</template>
																	<!-- <template slot="rem" slot-scope="data"> -->
																	<template v-slot:cell(rem)="data">
																		<b-button
																			variant="danger"
																			size="sm"
																			v-if="showBtnTrashByStatus(data)"
																			@click="delItem(data.item.id)"
																			class="btn bg-transparent border-0"
																		>
																			<component
																				:is="getLucideIcon(ICONS.TRASH.name)"
																				:color="ICONS.TRASH.color"
																				:size="20"
																			/>
																		</b-button>
																	</template>
																</b-table>
															</b-overlay>
														</b-col>
													</b-row>
													<loading :active.sync="isLoading" :is-full-page="true"></loading>
													<b-modal
														:title="FormMSG(27, 'Success!')"
														class="modal-success"
														v-model="successModal"
														@ok="successModal = false"
														ok-variant="success"
														ok-only
														modal-class="mui-animation"
														:fade="false"
													>
														{{ this.FormMSG(28, 'The new document has been saved.') }}
													</b-modal>
													<b-modal
														:title="FormMSG(27, 'Success!')"
														class="modal-success"
														v-model="successModalDelete"
														@ok="successModalDelete = false"
														ok-variant="success"
														ok-only
														modal-class="mui-animation"
														:fade="false"
													>
														{{ this.FormMSG(29, 'Your document has been deleted.') }}
													</b-modal>
												</div>
											</b-tab>
											<b-tab v-if="canSeeDocument && useDoc" lazy :title="FormMSG(190, 'Document package')" :active="activeTabDocPackage">
												<div>
													<table-hierarchical-doc-package />
												</div>
											</b-tab>
											<b-tab v-if="canSeeCallSheet" lazy :title="FormMSG(191, 'Call Sheets')">
												<Stageday />
											</b-tab>
										</b-tabs>
									</div>
								</b-card-text>
							</b-card>
						</b-col>
					</b-row>
				</div>
			</div>
		</transition>
	</errorContainer>
</template>

<script>
import { isNil } from '@/shared/utils';
import gql from 'graphql-tag';
import { store } from '@/store/index';
import moment from 'moment';
import Loading from 'vue-loading-overlay';
import { getFileExtension } from '@/shared/helpers';
import languageMessages from '@/mixins/languageMessages';
import { getDocumentList } from '@/modules/document-package/cruds/document.crud';
import TableHierarchicalDocPackage from '@/modules/document-package/components/TableHierarchicalDocPackage';
import { Search, X } from 'lucide-vue';
import globalMixin from '@/mixins/global.mixin';
import Stageday from '../stageday/stageday.vue';

const axios = require('axios').create();

const mutation_curDocUpd = gql`
	mutation ($DocumentId: ID!, $UpdatedDocument: DocumentInput!) {
		UpdDocument(DocumentId: $DocumentId, UpdatedDocument: $UpdatedDocument) {
			id
			type
			name
			fileName
			body
			userPassword
			ownerPassword
			xid
			xidGenerated
			ext
			useWatermark
			watermarkPictureXid
			watermarkPictureFileName
			watermarkText
			watermarkWithUser
			publishedOn
			publisherId
			toSign
			toEmail
			toSms
			toWhatsApp
			availableOnline
			publisher {
				name
				firstName
			}
		}
	}
`;

const mutation_curDocDel = gql`
	mutation ($DocumentId: ID!) {
		DelDocument(DocumentId: $DocumentId) {
			id
			type
			name
			fileName
			body
			userPassword
			ownerPassword
			xid
			xidGenerated
			ext
			useWatermark
			watermarkPictureXid
			watermarkPictureFileName
			watermarkText
			watermarkWithUser
			publishedOn
			publisherId
			toSign
			toEmail
			toSms
			toWhatsApp
			availableOnline
		}
	}
`;

const mutation_newDoc = gql`
	mutation {
		NewDocument {
			id
			type
			name
			fileName
			body
			userPassword
			ownerPassword
			xid
			xidGenerated
			ext
			useWatermark
			watermarkPictureXid
			watermarkPictureFileName
			watermarkText
			watermarkWithUser
			publishedOn
			publisherId
			toSign
			toEmail
			toSms
			toWhatsApp
			availableOnline
			publisher {
				name
				firstName
			}
		}
	}
`;

export default {
	name: 'Documents',
	components: { Loading, TableHierarchicalDocPackage, Search, X, Stageday },
	mixins: [languageMessages, globalMixin],
	props: {
		hover: {
			type: Boolean,
			default: true
		},
		striped: {
			type: Boolean,
			default: true
		},
		bordered: {
			type: Boolean,
			default: true
		},
		small: {
			type: Boolean,
			default: false
		},
		fixed: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			hv: 'dark',
			erreur: {},
			formDataObj: {},
			Docs: [],
			curDoc: {},
			newDoc: {},
			currentPage: 1,
			currentIdx: 0,
			perPage: 0,
			warning: '',
			filter: '',
			isLoading: false,
			pdfFile: null,
			wmImageFile: null,
			newCreated: 0,
			displayAllCategories: false,
			stopPropagation: '',
			nativeEvent: '',
			selectMode: 'single',
			selectedColor: 'primary',
			successModal: false,
			successModalDelete: false,
			firstTime: true,
			lastPublishedOn: '',
			hideArchived: false,
			activeTabDocPackage: false,
			tabsValue: 0,
			isBusyDataTable: false
		};
	},
	computed: {
		useDoc() {
			return store.state.myProject.useDocument;
		},
		canSeeCallSheet() {
			return store.canManageCallSheets();
		},
		canSeeDocument() {
			return store.canManageDocuments();
		},
		optionsForDocType() {
			return this.FormMenu(1011);
		},
		docfields() {
			return [
				{
					key: 'name',
					label: this.FormMSG(50, 'Name'),
					sortable: true
				},
				{
					key: 'type',
					label: this.FormMSG(51, 'Type'),
					formatter: (value) => {
						return this.GetTextFromMenuNumberAndMenuValue(1011, value);
					},
					sortable: true
				},
				{
					key: 'fileName',
					label: this.FormMSG(52, 'File name'),
					sortable: true
				},
				{
					key: 'fullName',
					label: this.FormMSG(53, 'Publisher'),
					sortable: true
				},
				{
					key: 'createdAt',
					label: this.FormMSG(54, 'Date'),
					sortable: true,
					formatter: (value) => {
						return new moment(value).format('DD/MM/YYYY HH:mm');
					}
				},
				{
					key: 'isOnBoardingDocument',
					label: this.FormMSG(155, 'For onboarding'),
					sortable: true,
					class: 'text-center',
					formatter: (value) => {
						return value ? this.FormMSG(156, 'Yes') : this.FormMSG(157, 'No');
					}
				},
				{
					key: 'view',
					label: this.FormMSG(55, 'View'),
					sortable: false,
					formatter: (v) => v,
					class: 'text-center'
				},
				{
					key: 'rem',
					label: this.FormMSG(56, 'Delete'),
					sortable: false,
					formatter: (v) => v,
					class: 'text-center'
				}
			];
		},
		caption1() {
			return this.FormMSG(1, 'Document');
		},
		caption2() {
			return this.FormMSG(2, 'List of all documents');
		},
		disableSave() {
			if (
				this.newDoc.name != this.curDoc.name ||
				this.newDoc.type != this.curDoc.type ||
				this.newDoc.availableOnline != this.curDoc.availableOnline ||
				this.newDoc.toEmail != this.curDoc.toEmail ||
				this.newDoc.toSms != this.curDoc.toSms ||
				this.newDoc.toWhatsApp != this.curDoc.toWhatsApp ||
				this.newDoc.toSign != this.curDoc.toSign ||
				this.newDoc.watermarkWithUser != this.curDoc.watermarkWithUser ||
				this.newDoc.watermarkText != this.curDoc.watermarkText ||
				this.newDoc.useWatermark != this.curDoc.useWatermark ||
				this.newDoc.body != this.curDoc.body ||
				this.newDoc.userPassword != this.curDoc.userPassword ||
				this.newDoc.ownerPassword != this.curDoc.ownerPassword
			) {
				return false;
			}
			return true;
		}
	},

	mounted() {
		if (this.$route.query.viaTabDocPackage) {
			this.activeTabDocPackage = true;
		}
	},

	methods: {
		async handleChangeShowArchivedDocument(payload) {
			this.hideArchived = payload;
			this.isBusyDataTable = true;
			await getDocumentList({ hideArchivedDocument: this.hideArchived }).then((result) => {
				this.Docs = result;
				for (let i = 0; i < this.Docs.length; i++) {
					this.Docs[i].fullName = this.Docs[i].publisher.name + ' ' + this.Docs[i].publisher.firstName;
				}
				if (this.Docs.length > 0) {
					this.curDoc = this.Docs[0];
					this.newDoc = Object.assign({}, this.curDoc);
					var idx = store.getCurrentDocumentTableIndex();
					this.clickRow(idx);
					this.firstTime = false;
				} else {
					this.initValuesForNoRecords();
				}
				this.isBusyDataTable = false;
			});
		},
		showBtnTrashByStatus(data) {
			const statuesToCheck = [0, 1, 2];
			if (data.item.type !== 11) {
				if (statuesToCheck.includes(parseInt(data.item.status, 10)) && !data.item.isUsed) {
					return true;
				}
			} else {
				if (data.item.archived === 0) {
					return true;
				}
			}

			return false;
		},
		setPublishedDate() {
			if (this.alreadyPublished()) {
				var date =
					this.newDoc.publishedOn.split('T')[0].substring(8) +
					'/' +
					this.newDoc.publishedOn.split('T')[0].substring(5, 7) +
					'/' +
					this.newDoc.publishedOn.split('T')[0].substring(0, 4);
				date = date + ' ' + this.newDoc.publishedOn.substring(11, 13) + ':' + this.newDoc.publishedOn.substring(14, 16);
				return this.FormMSG(57, 'Published by:') + ' ' + this.newDoc.publisher.name + ' ' + this.newDoc.publisher.firstName + '    - ' + date;
			} else {
				return 'Not published yet';
			}
		},
		alreadyPublished() {
			var y = new Date('2013-05-23');
			var x = new Date(this.newDoc.publishedOn.substring(0, 10));
			if (x.getTime() > y.getTime()) {
				return true;
			} else {
				return false;
			}
		},
		DocPwdRequired() {
			// check if chosen pdf require password here
			return true;
		},
		viewDocument(item) {
			if (isNil(item)) return;

			const src = `${process.env.VUE_APP_GQL}/images/${item.xid}`;

			this.$previewImages({
				images: [
					{
						...item,
						src,
						fileName: item.fileName,
						xid: item.xid,
						ext: getFileExtension(item.xid)
					}
				],
				options: {
					presentationMode: 'doc',
					hideCommentButton: true,
					hideDisLikeButton: true,
					hideLikeDislike: true,
					hideCropButton: true,
					hideDeleteButton: true
				}
			});
		},
		isPdfFile() {
			// check if file is a pdf
			if (this.newDoc.fileName.length == 0) {
				return false;
			}
			var fileName = this.newDoc.fileName;
			var nameLen = fileName.length;
			var lastDotPos = fileName.lastIndexOf('.');
			var fileNameSub = false;
			if (lastDotPos === -1) {
				return false;
			} else {
				//Remove +1 if you want the "." left too
				fileNameSub = fileName.substr(lastDotPos + 1, nameLen).toLowerCase();
				if (fileNameSub == 'pdf') {
					return true;
				}
			}
			return false;
		},
		displayPdfPwd() {
			// if pdf and need to modify (tosign or watermarked)
			if (this.isPdfFile() == true && (this.newDoc.useWatermark == true || this.newDoc.toSign == true)) {
				return true;
			}
			return false;
		},
		showDocument() {
			// copy doc id in the store
			store.setCurrentDocumentId(this.newDoc.id);

			//console.log("item id:", item.id);
			const documentLink = `/documents/documentDetails/${this.newDoc.id.toString()} `;
			// console.log("starting:", documentLink);
			this.$router.push({
				path: documentLink
			});
		},
		showDeliveries() {
			// copy item content into curTs structure.
			store.state.curDocument = this.newDoc;
			//console.log("item id:", item.id);
			const deliveryLink = `/documents/delivery/${this.newDoc.id.toString()} `;
			// console.log("starting:", deliveryLink);
			this.$router.push({
				path: deliveryLink
			});
		},
		fillUpdatedDocument() {
			var UpdatedDocument = {};
			UpdatedDocument.type = parseInt(this.newDoc.type, 10);
			UpdatedDocument.name = this.newDoc.name;
			UpdatedDocument.fileName = this.newDoc.fileName;
			UpdatedDocument.body = this.newDoc.body.substring(0, 4000);
			UpdatedDocument.userPassword = this.newDoc.userPassword;
			UpdatedDocument.ownerPassword = this.newDoc.ownerPassword;
			UpdatedDocument.xid = this.newDoc.xid;
			UpdatedDocument.useWatermark = this.newDoc.useWatermark;
			UpdatedDocument.watermarkText = this.newDoc.watermarkText;
			UpdatedDocument.watermarkPictureXid = this.newDoc.watermarkPictureXid;
			UpdatedDocument.watermarkPictureFileName = this.newDoc.watermarkPictureFileName;
			UpdatedDocument.watermarkWithUser = this.newDoc.watermarkWithUser;
			UpdatedDocument.toSign = this.newDoc.toSign;
			UpdatedDocument.toEmail = this.newDoc.toEmail;
			UpdatedDocument.toSms = this.newDoc.toSms;
			UpdatedDocument.toWhatsApp = this.newDoc.toWhatsApp;
			UpdatedDocument.availableOnline = this.newDoc.availableOnline;
			return UpdatedDocument;
		},
		downloadCsv() {
			var urlStr = process.env.VUE_APP_GQL + '/templatedata/TGS_templateExample_en.pdf';

			window.open(urlStr); // in a new window
		},
		curDocUpd() {
			// loading variables from newDoc
			//console.log("before call to update Doc, this.newDoc:", this.newDoc);
			var UpdatedDocument = this.fillUpdatedDocument();
			var DocumentId = parseInt(this.newDoc.id, 10);
			this.$apollo
				.mutate({
					mutation: mutation_curDocUpd,
					variables: {
						DocumentId,
						UpdatedDocument
					}
				})
				.then(async (result) => {
					// modify curDoc from newDoc
					this.curDoc = Object.assign({}, result.data.UpdDocument);
					this.newDoc = Object.assign({}, result.data.UpdDocument);

					// find object in the list and update accordingly
					//console.log("after doc update result:", result);
					//console.log("after doc update newDoc:", this.newDoc);
					await this.reloadData();
				})
				.catch((error) => {
					console.log(error);
					//this.erreur = error
					var myerrorObject = {
						err: ''
					};
					myerrorObject.err = error.message;
					this.erreur = myerrorObject;
				});
		},
		initValuesForNoRecords() {
			this.newDoc.name = ' NEW DOCUMENT';
			this.newDoc.fileName = '';
			this.newDoc.type = parseInt('0', 10);
			this.newDoc.fileName = '';
			this.newDoc.userPassword = '';
			this.newDoc.ownerPassword = '';
			this.newDoc.xid = '';
			this.newDoc.useWatermark = false;
			this.newDoc.watermarkText = '';
			this.newDoc.watermarkPictureXid = '';
			this.newDoc.watermarkPictureFileName = '';
			this.newDoc.watermarkWithUser = false;
			this.newDoc.toSign = false;
			this.newDoc.toEmail = false;
			this.newDoc.toSms = false;
			this.newDoc.toWhatsApp = false;
			this.newDoc.availableOnline = true;
			this.newDoc.body = '';
		},
		async reloadData() {
			this.isBusyDataTable = true;
			await getDocumentList({
				showDocumentOnBoardingPackage: false
			}).then((result) => {
				// console.log('result:', result);
				this.Docs = result;
				for (let i = 0; i < this.Docs.length; i++) {
					this.Docs[i].fullName = this.Docs[i].publisher.name + ' ' + this.Docs[i].publisher.firstName;
					// this.Docs[i].fileName = this.replaceToRightFileNameExt(this.Docs[i].fileName, this.Docs[i].docSignedXid);
				}

				// select element
				if (this.Docs.length > 0) {
					this.curDoc = this.Docs[0];
					this.newDoc = Object.assign({}, this.curDoc);

					//console.log("reload data clickrow 0");
					var idx = store.getCurrentDocumentTableIndex();
					//console.log("var idx = store.state.getCurrentDocumentTableIndex", idx);
					this.clickRow(idx);
					this.firstTime = false;
				} else {
					//console.log("in reload data initValuesForNoRecords");
					this.initValuesForNoRecords();
				}
				this.isBusyDataTable = false;
			});
		},
		async delItem(id) {
			const action = async () => {
				const DocumentId = parseInt(id, 10);

				await this.$apollo.mutate({
					mutation: mutation_curDocDel,
					variables: {
						DocumentId
					}
				});

				await this.reloadData();
			};

			this.confirmModal(action, this.FormMSG(58, 'Are you sure to remove this document ?'));
		},
		getRowCount(items) {
			//console.log(items)
			return items.length;
		},
		rowClicked(item, index) {
			// open document window with doc selected
			// console.log("item in rowClicked  :", item);
			this.curDoc = item;
			this.newDoc = Object.assign({}, item);
			this.showDocument();
			return;
			// // copy item content into curDoc structure.
			// //                this.$refs.myTable.clearSelected();
			// console.log("item in rowClicked  :", item);
			// console.log("index in rowClicked  :", index);
			// // compute currentIdx
			// this.currentIdx = (this.currentPage - 1) * this.perPage + index;
			// store.setCurrentDocumentTableIndex(this.currentIdx);
			// //console.log("item in rowClicked index:", this.currentIdx);
			// this.curDoc = item;
			// this.newDoc = Object.assign({}, item);
			// this.lastPublishedOn = this.setPublishedDate();
			// //  console.log("item in rowClicked  this.$refs.myTable:",  this.$refs.myTable);
			// this.$refs["pdfFileId"].reset();
		},
		NewDocument() {
			this.$apollo
				.mutate({
					mutation: mutation_newDoc,
					variables: {}
				})
				.then((result) => {
					// reload the Data
					// save new to newDoc
					this.newDoc = result.data.NewDocument;
					// open document window with doc selected
					this.showDocument();
				})
				.catch((error) => {
					console.log(error);
					this.erreur = error;
				});
		},
		clickRow(idx) {
			return;
			this.lastPublishedOn = this.setPublishedDate();
			if (this.perPage == 0) {
				this.currentPage = 1;
			} else {
				this.currentPage = parseInt(parseInt(idx, 10) / parseInt(this.perPage, 10), 10) + 1;
			}
			store.setCurrentDocumentTableIndex(idx);
			this.$nextTick(function () {
				// Code that will run only after the
				// entire view has been re-rendered
				// found in https://jsfiddle.net/sem409uc/
				let myTable = this.$refs.myTable.$el,
					tableBody = myTable.getElementsByTagName('tbody')[0],
					tableRows = tableBody.getElementsByTagName('tr');
				if (this.perPage == 0) {
					tableRows[idx].click();
				} else {
					tableRows[idx % this.perPage].click();
				}
			});
		},
		removeDocument(xid) {
			// console.log("removing document :", xid);
			var retStr = '';
			let formData = new FormData(); // instantiate it
			formData.append('fileName', xid);
			var config = {
				headers: {
					Authorization: `Bearer ${store.state.token}`,
					'content-type': 'multipart/form-data'
				}
			};
			var urlStr = process.env.VUE_APP_GQL + '/removedocument';
			axios
				.post(urlStr, formData, config)
				.then((response) => {
					retStr = response.data;
					//console.log("document removed:", retStr);
					this.isLoading = false;
				})
				.catch((error) => {
					console.log(error);
				});
			return retStr;
		},
		removeWmFile() {
			if (this.newDoc.watermarkPictureFileName.length > 0) {
				// new watermark selected, remove the old file from the server
				this.removeDocument(this.newDoc.watermarkPictureXid);
			}
			this.newDoc.watermarkPictureFileName = '';
			this.newDoc.watermarkPictureXid = '';
			this.curDocUpd();
		},
		submitWmFile(ev) {
			this.isLoading = true;
			if (ev.type == 'drop') {
				var wmImgFile = ev.dataTransfer.files[0];
			} else if (ev.type == 'change') {
				var wmImgFile = ev.target.files[0];
			}
			//console.log("submitWmFile submit file:", wmImgFile);

			if (this.newDoc.watermarkPictureFileName.length > 0) {
				// new watermark selected, remove the old file from the server
				this.removeDocument(this.newDoc.watermarkPictureXid);
			}

			this.newDoc.watermarkPictureFileName = wmImgFile.name;
			this.formDataObj = new FormData();
			this.formDataObj.append('uploadimage', wmImgFile);
			this.formDataObj.append('fileName', wmImgFile.name);
			this.formDataObj.append('fileType', wmImgFile.type);
			this.formDataObj.append('resize', '1024');
			var urlStr = process.env.VUE_APP_GQL + '/upload';
			//console.log("submit file before axios call:", urlStr);

			// console.log('wmImgFIle submitWmFile:', wmImgFile);
			var fd = this.formDataObj;
			axios
				.post(urlStr, this.formDataObj, {
					headers: {
						//...axios.defaults.headers,
						//  'Content-Type': `multipart/form-data; boundary=${form._boundary}`,
						Authorization: `Bearer ${store.state.token}`,
						'Content-Type': 'multipart/form-data'
						//fd.getHeaders()
					}
				})
				.then((result) => {
					//window.location.reload();
					// console.log('boundary:', this.formDataObj.getAll("uploadimage"));
					this.newDoc.watermarkPictureXid = result.data;
					// console.log("submitWmFile success this.newDoc.watermarkPictureXid:", this.newDoc.watermarkPictureXid);
					// save document with update
					this.curDocUpd();
					this.$refs['wmFileFieldId'].reset();
				})
				.catch(function () {
					console.log('boundary:', this.formDataObj.getAll('uploadimage'));
					console.log(' submitWmFile FAILURE!!');
				})
				.finally(() => (this.isLoading = false));
		},
		removePdfFile() {
			if (this.newDoc.xid.length > 0) {
				//console.log("in submitDocFile remove old doc:", this.newDoc.xid)
				// new document selected, remove the old file from the server
				this.removeDocument(this.newDoc.xid);
				if (this.newDoc.xidGenerated.length > 0) {
					// remove also the previous xidGenerated if any
					this.removeDocument(this.newDoc.xidGenerated);
				}
			}
			this.newDoc.fileName = '';
			this.newDoc.xid = '';
			this.newDoc.xidGenerated = '';
			this.curDocUpd();
		},
		submitDocFile(ev) {
			this.isLoading = true;
			if (ev.type == 'drop') {
				var docFil = ev.dataTransfer.files[0];
			} else if (ev.type == 'change') {
				var docFil = ev.target.files[0];
			}
			//console.log("submitDocFile submit file:", docFil);
			let formData = new FormData();
			formData.append('uploadimage', docFil);
			formData.append('fileName', docFil.name);
			if (this.newDoc.xid.length > 0) {
				//console.log("in submitDocFile remove old doc:", this.newDoc.xid)
				// new document selected, remove the old file from the server
				this.removeDocument(this.newDoc.xid);
				if (this.newDoc.xidGenerated.length > 0) {
					// remove also the previous xidGenerated if any
					this.removeDocument(this.newDoc.xidGenerated);
				}
			}

			this.newDoc.fileName = docFil.name;
			formData.append('fileType', docFil.type);
			//console.log("in submitdocfile, fileName:", this.newDoc.fileName);
			var urlStr = process.env.VUE_APP_GQL + '/upload';
			// console.log("in submitdocfile, urlStr:", urlStr);
			// console.log("in submitdocfile, formData:", formData);
			axios
				.post(urlStr, formData, {
					headers: {
						Authorization: `Bearer ${store.state.token}`,
						'Content-Type': 'multipart/form-data'
					}
				})
				.then((result) => {
					//console.log("submitDocFile success result:", result);
					this.newDoc.xid = result.data;
					this.newDoc.xidGenerated = '';
					// save document with update
					this.curDocUpd();
					this.$refs['pdfFileId'].reset();
				})
				.catch(function () {
					console.log(' submitDocFile FAILURE!!');
				})
				.finally(() => (this.isLoading = false));
		}
	},
	async created() {
		await this.reloadData();
	}
};
</script>

<style scoped>
.card-body >>> table > tbody > tr > td {
	cursor: pointer;
}

input[type='texte'] {
	width: 100%;
	padding: 12px 20px;
	margin: 4px 0;
	box-sizing: border-box;
	border: none;
	background-color: white;
	color: white;
}
</style>
